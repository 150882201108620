<template>
  <v-card elevation="0" class="pa-0 my-4 cardConfirm">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col cols="8">
          <div class="py-2 px-3 cardConfirmlineB cardConfirmlineR cardCBox">
            {{ value.product_name }}
          </div>
        </v-col>
        <v-col cols="4">
          <div
            class="
              py-2
              px-3
              text-center
              cardConfirmlineB
              cardConfirmlineL
              cardCBox
              priceBox
              top
            "
            elevation="0"
            tile
          >
            預估小計
          </div>
        </v-col>
        <v-col cols="4">
          <div
            class="
              py-2
              px-3
              cardConfirmlineT
              cardConfirmlineR
              cardCBox
            "
            elevation="0"
            tile
          >
            {{price}} {{priceSuffix}}
          </div>
        </v-col>
        <v-col cols="4">
          <div
            class="
              py-2
              px-3
              cardConfirmlineT
              cardConfirmlineL
              cardConfirmlineR
              cardConfirm--price
              cardCBox
            "
            elevation="0"
            tile
          >
            數量： {{ value.order_count }} {{orderCountSuffix}}
          </div>
        </v-col>
        <v-col cols="4">
          <div
            class="
              py-2
              px-3
              text-center
              cardConfirmlineT
              cardConfirmlineL
              cardCBox
              priceBox
              bottom
            "
            elevation="0"
            tile
          >
            {{ amount | fixedPrice }} 元
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import unitText from "@/mixins/unitText.js";

export default {
  mixins: [unitText],
  props: {
    value: {
      type: Object,
      // default: () => ({
      //   product_name: "鮭魚",
      //   shipping_unit: "籃", // 出貨單位
      //   calculate_unit: "包", // 計價單位
      //   per_shipping_count: 5, //  ex: 規格:每籃5包
      //   price: 10, // 單價
      //   order_count: 40, // 訂購數量 / 數量
      // }),
    },
  },
  computed: {
    isUnitSame() {
      if (!this.value.shipping_unit) return false;
      if (!this.value.calculate_unit) return false;
      return this.value.shipping_unit === this.value.calculate_unit;
    },
    price() {
      return this.value.price
    },
    amount() {
      let amount = null;
      const count = Number(this.$helper.transformShippingCount({
        count: this.value.order_count,
        unit: this.value.shipping_unit
      }))
      if (this.isUnitSame) {
        amount = Number(this.price) * count
      } else {
        amount =
          Number(this.price) *
            count *
          Number(this.value.per_shipping_count);
      }
      return isNaN(amount) ? null : amount;
    },
    priceSuffix() {
      return `元 / ${this.value.calculate_unit}`
    },
    orderCountSuffix() {
      if(this.isUnitSame) return this.value.shipping_unit
      return this.value.shipping_unit
    },
  },
};
</script>

<style lang="sass">
.cardConfirm
  background: #F6F6F8 !important
  border-radius: 5px !important
  &lineT
    border-top: 1px solid #ffffff
  &lineB
    border-bottom: 1px solid #ffffff
  &lineL
    border-left: 1px solid #ffffff
  &lineR
    border-right: 1px solid #ffffff
  &--price
    color: #006DE0
  .priceBox
    background: #006DE0
    color: #ffffff
    &.top
      border-radius: 0 5px 0 0
      border-bottom: 1px solid #f5f5f51a
      border-left: 1px solid #006DE0
    &.bottom
      border-radius: 0 0 5px 0
      border-top: 1px solid #006DE0
      border-left: 1px solid #006DE0
</style>